const VETIFY_LOGIN_COOKIE_KEY = "VETIFY-USER-LOGGED";

export default () => {
    const loggedCookie = useCookie(VETIFY_LOGIN_COOKIE_KEY, { maxAge: 86400 });
    const isLoggedIn = computed(() => (loggedCookie.value && loggedCookie.value !== null ? true : false));

    const login = (nif: string) => {
        loggedCookie.value = JSON.stringify({
            nif,
        });
    };

    const logout = () => {};

    return {
        isLoggedIn,
        login,
        logout,
    };
};
